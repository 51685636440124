<template>
  <div class="Check-header">
    <div class="Check-header-one">
      <p>
        <van-icon name="arrow-left" size="24px" />
      </p>

      <p>订单详情</p>
    </div>
    <div class="Check-header-two">
      <van-card
        desc="4小时丨小包'+'营业时间 12:00～02:00"
        title="小包4小时畅爽嗨唱"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      >
        <template #tags>
          <van-desc>包河区金寨路与望江东路交口南七里站商业大厦旁二楼（采蝶轩东边10米，上停车场）</van-desc>
        </template>
      </van-card>
      <van-divider />
      <div class="Check-center">
        <p>票劵核验</p>
        <div class="Check-footer">
          <p class="cneter-two">
            <img src="../../static/会员升级_slices/1111.png" alt />
          </p>
          <p class="cneter-one">2张票劵</p>
          <p class="cneter-tress">兑换码: 2313 2312 2131 23</p>
        </div>
      </div>
      <van-divider />
      <div class="Check-buttom">
        <p>
          <van-card desc="包河区金寨路与望江东路交口南七里站商业大厦旁二楼（采蝶轩东边10米，上停车场）" title="台北原唱量板ktv(南七店)" />
        </p>
        <p class="Check-right">
          <img src="../../static/会员升级_slices/操作-电话_slices/操作-电话.png" alt />
        </p>
      </div>
      <van-divider />
      <div class="Check-center-book">
        <van-card
          desc="订单号:  16797818881 61548850 购买时间: 2021-03-28 15:52:35 手机号: 138****7359"
          title="实付金额：¥168"
        />
      </div>

      <!-- <van-divider /> -->
      <div class="Check-footeer">
        <p>联系客服</p>
        <p>
          <van-icon name="arrow" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Check",
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.Check-header {
  top: 0;
  left: 0;
  right: 0;
  height: 890px;
  background-color: rgba(0, 175, 255, 1);

  .Check-header-one {
    position: fixed;
    left: 0;
    right: 0;
    height: 44px;
    // border: 1px solid red;
    padding: 22px 0 10px 10px;
    color: #fff;
    display: flex;
    z-index: 99999;
    background-color: rgba(0, 175, 255, 1);
    :last-child {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }
  }
  .Check-header-two {
    position: absolute;
    top: 44px;
    // height: 780px;
    left: 0;
    right: 0;
    margin: 40px 16px 0 16px;
    background-color: rgba(255, 255, 255, 1);
    // border: 1px solid #fff;
    border-radius: 10px;
    .van-card {
       border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
    }

    .Check-center {
      // height: 401px;
      // border: 1px solid red;
      .Check-footer {
        flex-direction: column;
        text-align: center;
        .cneter-two {
          margin-top: 40px;
        }
        .cneter-one {
          margin-top: 25px;
        }
        .cneter-tress {
          // width: 271px;
          // height: 20px;
          margin: 16px 36px 0 36px;
          border: 1px solid #666;
          padding: 15px 0 15px 0;
          // line-height: 50px;
        }
      }
      :first-child {
        margin: 9px 0 0 15px;
        font-size: 14px;
      }
    }
  }
  .Check-buttom {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    height: 97px;
    .van-card {
      width: 220px;
      font-size: 12px;
    }
    .Check-right {
      // text-align: center;
      margin: 36px 15px 0 0;
    }
  }
  // .Check-toper {
  //   border-top: 1px dashed #666;
  //   height: 1px;
  //   padding: 20px 15px 0 15px;
  // }
  .Check-center-book{
    // height: 114px;
    background-color: #fff;
  }
  .Check-footeer {
    display: flex;
    // height: 59px;
    justify-content: space-between;
    background-color: #fff;
    text-align: center;
    padding: 0 19px 0 15px;
    border-radius: 10px;
  }
}
</style>